.login-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.login-container .form-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-body .btn {
  display: block;
  margin: 0 auto;
}
